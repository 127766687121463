import { render, staticRenderFns } from "./JTreeDict.vue?vue&type=template&id=a179abc6&"
import script from "./JTreeDict.vue?vue&type=script&lang=js&"
export * from "./JTreeDict.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a179abc6')) {
      api.createRecord('a179abc6', component.options)
    } else {
      api.reload('a179abc6', component.options)
    }
    module.hot.accept("./JTreeDict.vue?vue&type=template&id=a179abc6&", function () {
      api.rerender('a179abc6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/jeecg/JTreeDict.vue"
export default component.exports