var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.arrayValue,
        mode: "multiple",
        placeholder: _vm.placeholder,
      },
      on: { change: _vm.onChange },
    },
    _vm._l(_vm.options, function (item, index) {
      return _c(
        "a-select-option",
        {
          key: index,
          attrs: {
            getPopupContainer: _vm.getParentContainer,
            value: item.value,
          },
        },
        [_vm._v("\n    " + _vm._s(item.text || item.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }